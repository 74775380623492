import React from "react"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const CaptionStandardization = () => (
  <Container fluid>
        <Row>
            <Col>
                <div className="captionStandardization">
                    <h1>A simpler way to standardize cardiac patient monitoring connections</h1>
                </div>
            </Col>
        </Row>
  </Container>
)

export default CaptionStandardization

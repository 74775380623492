import React from "react"
import { Link } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import EntireStayImg from '../../images/leadwire-entire-stay.jpg'
import CompatibleImg from '../../images/leadwire-compatability.png'
import ContinuumImg from '../../images/standardization.png'
import IntroImg from '../../images/standardization-six-steps.jpg'


const StandardizationProvenProcess = () => (
  <div className="provenProcess">
    <Container fluid className="continuum">
        <Row>
          <Col md={6} className="d-flex align-items-center order-md-0 order-1">
            <div className="imgWrap">
              <img src={ContinuumImg} alt="Intrahospital Continuum of Care" className="resp-img" />
            </div>
          </Col>
          <Col md={6} className="order-md-1 order-0">
            <h2>"Ready connect" standardization<br />for patient flow efficiency</h2>
            <p>Multiple leadwires and monitor connections can create disorder with different and competing connections, 
            baffling staff, and slowing patient care.</p>
            <p>A single ECG leadwire across your hospital 
            helps your staff maintain high efficiency, track inventory, 
            and streamline procurement.</p>
            <p>LifeSync's AMC Leadwire System - LWS® is the only complete system that allows hospitals to standardize and elevate efficiency throughout the facility.</p>
          </Col>
        </Row>
    </Container>
    <Container fluid className="leadwire">
        <Row>
          <Container>
              <Row>
                <Col md={6}>
                  <h2>A single leadwire that is compatible with your existing monitors</h2>
                  <p>The LifeSync leadwire system (formerly AMC) plugs directly into monitors, telemetry, and defibrillation connections. No adapters or cable extension are needed in most cases.</p>
                </Col>
                <Col md={6}>
                  <div className="imgWrapTop">
                    <img src={CompatibleImg} alt="Leadire is compatible with your existing monitors" className="resp-img" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="order-md-0 order-1">
                  <div className="imgWrap">
                    <img src={EntireStayImg} alt="One leadwire for patient's entire stay" className="resp-img" />
                  </div>
                </Col>
                <Col md={6} className="order-md-1 order-0">
                  <h2 style={{marginTop: '1em'}}>One leadwire for the patient's entire stay</h2>
                  <p>LifeSync leadwires travel with the patient by simply unpluging from one monitor and connecting to the next monitor. The single-patient-use leadwire is designed to avoid the cross-contamination risks associated with reusable leadwires.</p>
                </Col>
              </Row>
          </Container>
        </Row>
    </Container>
    <Container className="costs">
      <Row>
        <Col md={6} className="border-right border-md">
            <h2>Reusable cost considerations</h2>
            <ul>
              <li>Cross-contamination, which may lead to increased time in patient stay.</li>
              <li>Sterilization cost, to include the training of processing department.</li>
            </ul>
            <p className="subscript">*Based on customer feedback</p>
        </Col>
        <Col md={6}>
            <h2>Single-patient-use cost efficiencies</h2>
            <ul>
              <li>AMC Leadwire System - LWS&reg; are cost-neutral compared to reusable leadwires.</li>
              <li>No cost due to cleaning.</li>
              <li>No cost due to processing time.</li>
            </ul>
        </Col>
      </Row>
    </Container>
    <Container fluid className="easyanswer">
        <Row>
          <Container>
              <Row>
                <Col>
                  <h2>Easy answer to a daunting task</h2>
                  <p>The LifeSync team follows a transparent standardization process of complete inventory, training, supply chain management, and implementation. Our team makes a seamless transition possible for your team.</p>
                </Col>
              </Row>
          </Container>
        </Row>
    </Container>
    <Container className="sixsteps">
      <Row>
        <Col>
          <h2>Six steps to standardization</h2>
        </Col>
      </Row>
      <Row>
        <Col md={5}>            
          <p className="bottom">Standardizing or replacing cardiac cables and connections can feel like an enormous task. Our expert staff and conversion teams will guide you through the process of inventory, training, supply chain management, and implementation to ensure a successful transition. We use our six phase standardization process to ensure a seamless transition to LifeSync Cardiac monitoring connections.</p>
        </Col>
        <Col md={7} className="text-center">
          <div className="imgWrapBtm text-center">
              <img src={IntroImg} alt="Six steps to standardization" className="resp-img" />
            </div>
        </Col>
      </Row>
      <Row>
        <Col className="contactUs">
          <h3>Stay focused on patient care and leave the standardizing to us</h3>
          <p>To find out what standardization might look like for your hospital, contact your LifeSync representative or <Link to="/contact/">Customer Service online</Link> or by telephone, 1.800.358.2468. </p>
        </Col>
      </Row>
    </Container>
  </div>
)

export default StandardizationProvenProcess

import React from "react"

import LayoutStandardization from "../components/Standardization/layoutStandardization"
import SEO from "../components/seo"

import StandardizationProvenProcess from '../components/Standardization/standardizationProvenProcess'

const Standardization = () => (
  <LayoutStandardization>
    <SEO 
      title="LifeSync Standardization | LifeSync" 
      description="Coming soon... "
      ogimg="https://www.lifesync.com/static/hero-home-p-68887cab881dc915bb49debf1d67f892.jpg"
      link="https://www.lifesync.com/standardization/"
    />
    <StandardizationProvenProcess />
  </LayoutStandardization>
)

export default Standardization
